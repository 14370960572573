import { isDefined } from "@clipboard-health/util-ts";
import { IonBackButton, IonButtons, IonPage } from "@ionic/react";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { useGetAttendanceScoreProfile } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import {
  ATTENDANCE_SCORE,
  CLIPBOARD_SCORE,
  RELIABILITY_SCORE,
} from "@src/appV2/AttendanceScore/constants";
import { useClipboardScoreStatus } from "@src/appV2/ClipboardScore/useClipboardScoreStatus";
import { useGetFacilitiesExclusions } from "@src/appV2/Facilities/api/useGetFacilitiesExclusions";
import { AppBarHeader, PageWithHeader } from "@src/appV2/lib";
import { useIsShiftDiscoveryEnabled } from "@src/appV2/redesign/ShiftDiscovery/useIsShiftDiscoveryEnabled";
import { useReliabilityRankingExperiment } from "@src/appV2/ReliabilityRank/useReliabilityRankExperiment";
import { STREAK_TRACKER_PATH } from "@src/appV2/Streaks";
import {
  useStreaksEnabledForHcp,
  useStreaksEnabledGlobally,
} from "@src/appV2/Streaks/api/featureFlagHooks";
import { useGetWorkerActiveStreaks } from "@src/appV2/Streaks/api/useGetWorkerActiveStreaks";
import {
  filterStreaksByDateRange,
  getCurrentActiveStreaks,
  getPayBoostActiveStreaks,
} from "@src/appV2/Streaks/utils";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useQueryClient } from "@tanstack/react-query";
import { startOfWeek, subWeeks } from "date-fns";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";

import { getWorkplaceCountPath } from "./api/getPreferredWorkplaceCount";
import { RatingCardProps } from "./cards/RatingsCard";
import { RatingCardContainer } from "./cards/RatingsCardContainer";
import { pluralizeWorkplace } from "./lang";
import { logViewDetailsClicked } from "./logs";
import { getScoreSeverity } from "./utils";

export function RatingsPage() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { userId } = useDefinedWorker();

  const {
    isLoading: isAttendanceScoreLoading,
    data: attendanceScoreData,
    refetch: refetchAttendanceScore,
    isSuccess: isAttendanceScoreSuccess,
  } = useGetAttendanceScoreProfile(userId);

  const reliabilityRankingExperiment = useReliabilityRankingExperiment(attendanceScoreData);
  const isStreaksEnabledGlobally = useStreaksEnabledGlobally();
  const isStreaksOnForHcp = useStreaksEnabledForHcp();
  const { isWorkerClipboardScoreTargeted } = useClipboardScoreStatus();
  const {
    isLoading: isStreaksLoading,
    data: streaksResponse,
    refetch: refetchStreaks,
  } = useGetWorkerActiveStreaks(
    { agentId: userId },
    { enabled: !isEmpty(userId) && isStreaksEnabledGlobally }
  );

  const profileScore = useMemo(() => {
    if (reliabilityRankingExperiment.enabled) {
      return reliabilityRankingExperiment.data.reliabilityScore;
    }

    if (isWorkerClipboardScoreTargeted) {
      return attendanceScoreData?.clipboardScore;
    }

    return attendanceScoreData?.score;
  }, [reliabilityRankingExperiment, attendanceScoreData, isWorkerClipboardScoreTargeted]);

  const percentile = useMemo(() => {
    if (reliabilityRankingExperiment.enabled) {
      const { rank, outOf } = reliabilityRankingExperiment.data.reliabilityScoreRank;
      return Math.round((1 - rank / outOf) * 100);
    }
    return null;
  }, [reliabilityRankingExperiment]);

  const scoreTitle = useMemo(() => {
    if (reliabilityRankingExperiment.enabled) {
      return RELIABILITY_SCORE;
    }
    if (isWorkerClipboardScoreTargeted) {
      return CLIPBOARD_SCORE;
    }
    return ATTENDANCE_SCORE;
  }, [reliabilityRankingExperiment, isWorkerClipboardScoreTargeted]);

  const scoreDescription = useMemo(() => {
    if (reliabilityRankingExperiment.enabled && isDefined(percentile)) {
      const isAboveAverage = percentile > 50;
      const scoreText = isAboveAverage ? `top ${100 - percentile}%` : `bottom ${percentile}%`;

      return `You are in the ${scoreText} of professionals. Learn more about how Reliability Rank works.`;
    }

    return `Your account's ${scoreTitle} is ${profileScore}. Learn more about how ${scoreTitle} works.`;
  }, [reliabilityRankingExperiment, percentile, scoreTitle, profileScore]);

  const displayValue = useMemo(() => {
    if (reliabilityRankingExperiment.enabled && isDefined(percentile)) {
      return percentile;
    }
    return isDefined(profileScore) ? profileScore : 0;
  }, [reliabilityRankingExperiment, percentile, profileScore]);

  const handleAttendanceScoreClicked = () => {
    logViewDetailsClicked(userId, "attendance score");
    history.push(TabRouterPath.RATINGS_ATTENDANCE_SCORE);
  };

  const payBoostActiveStreaks = getPayBoostActiveStreaks(streaksResponse?.data);
  const exclusionsQueries = useGetFacilitiesExclusions({
    facilityIds: payBoostActiveStreaks.map((streak) => streak.attributes.facilityId),
    agentId: userId,
  });

  const excludedFacilities = new Set(
    exclusionsQueries
      .flatMap((queryResult) => queryResult.data?.data)
      .map((exclusion) => exclusion?.facility.userId)
      .filter((id) => isDefined(id))
  );

  const streaksAtValidFacilities = payBoostActiveStreaks.filter((streak) => {
    return !excludedFacilities.has(streak.attributes.facilityId);
  });

  const currentPayBoostActiveStreaks = getCurrentActiveStreaks(streaksAtValidFacilities);

  const hasPayBoostActiveStreakInTheLast2Weeks =
    filterStreaksByDateRange({
      streaks: streaksAtValidFacilities,
      from: subWeeks(startOfWeek(new Date()), 2),
    }).length > 0;

  const isLoading = isAttendanceScoreLoading || (isStreaksEnabledGlobally && isStreaksLoading);

  const cards: RatingCardProps[] = [];
  if (isAttendanceScoreSuccess && isDefined(profileScore)) {
    cards.push({
      title: scoreTitle,
      description: scoreDescription,
      buttonText: "See details",
      buttonOnClick: handleAttendanceScoreClicked,
      scoreValue: reliabilityRankingExperiment.enabled ? 100 - displayValue : displayValue,
      scoreColor: getScoreSeverity(displayValue),
      testId: "attendance-score",
    });
  }

  if (isStreaksEnabledGlobally && (isStreaksOnForHcp || hasPayBoostActiveStreakInTheLast2Weeks)) {
    cards.push({
      title: "Streaks (Beta)",
      description: `You have active streaks at ${pluralizeWorkplace(
        currentPayBoostActiveStreaks.length
      )}. Earn bonuses at workplaces where you have a streak. Learn more about how Streaks works.`,
      buttonText: "See details",
      buttonOnClick: () => {
        logViewDetailsClicked(userId, "streaks");
        history.push(STREAK_TRACKER_PATH);
      },
      scoreValue: currentPayBoostActiveStreaks.length,
      scoreColor: "streak",
      testId: "streaks",
    });
  }

  function refetchPreferredWorkplace() {
    const path = getWorkplaceCountPath(userId);
    queryClient.invalidateQueries([path]);
  }

  function refetchWorkerRankings() {
    queryClient.invalidateQueries({
      predicate: (query) => {
        return query.queryKey?.[0]?.toString().includes("/rankings") ?? false;
      },
    });
  }
  const isShiftDiscoveryEnabled = useIsShiftDiscoveryEnabled();

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            leftCta={
              isShiftDiscoveryEnabled ? (
                <IonButtons slot="start">
                  <IonBackButton text="" defaultHref={TabRouterPath.ACCOUNT} mode="ios" />
                </IonButtons>
              ) : null
            }
            title="Account Status"
          />
        }
        containerVariant="without-margin"
        containerMaxWidth="lg"
        onRefresh={() => {
          return Promise.all([
            refetchAttendanceScore(),
            refetchWorkerRankings(),
            refetchPreferredWorkplace(),
            isStreaksEnabledGlobally ? refetchStreaks() : Promise.resolve(),
          ]);
        }}
      >
        <RatingCardContainer cards={cards} isLoading={isLoading} />
      </PageWithHeader>
    </IonPage>
  );
}
